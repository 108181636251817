import { render, staticRenderFns } from "./blank.vue?vue&type=template&id=3191b007"
import script from "./blank.vue?vue&type=script&lang=js"
export * from "./blank.vue?vue&type=script&lang=js"
import style0 from "./blank.vue?vue&type=style&index=0&id=3191b007&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports